import React, { useEffect, useRef } from "react"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"

function LoadingOverlay({ children }) {
  const overlayRef = useRef()
  const { pathname } = useLocation()
  useEffect(() => {
    const overlay = overlayRef.current
    if (!overlay) return

    const allLinks = document.querySelectorAll("a:not([target='_blank'])")

    function handleLoadingIndicator(e) {
      e.preventDefault()
      const path = e.target.getAttribute("href")
      if (path === pathname) return
      overlay.classList.add("active")
      setTimeout(() => navigate(path), 100)
    }

    function pageLoad() {
      overlay.classList.remove("active")
    }

    pageLoad()

    allLinks.forEach(link => link.addEventListener("click", handleLoadingIndicator))
    return () => allLinks.forEach(link => link.removeEventListener("click", handleLoadingIndicator))
  }, [pathname])
  return (
    <>
      {children}
      <div className='overlay' ref={overlayRef} />
    </>
  )
}

export default LoadingOverlay
